export const PRIVILEGE_CODE = {
  ALL_VEHICLES: 'PRIVILEGE_ALL_VEHICLES',
  SEARCH: 'PRIVILEGE_SEARCH',
  ALLOCATION_APPLICATION: 'PRIVILEGE_ALLOCATION_APPLICATION',
  FILE_UPLOAD_ETA_VESSEL_NAME: 'PRIVILEGE_FILE_UPLOAD_ETA_VESSEL_NAME',
  FILE_UPLOAD_TO_BE_CANCEL_LIST: 'PRIVILEGE_FILE_UPLOAD_TO_BE_CANCEL_LIST',
  FILE_UPLOAD_PBP_ALLOCATION_BILLING: 'PRIVILEGE_FILE_UPLOAD_PBP_ALLOCATION_BILLING',
  FILE_UPLOAD_MODELD_VU_MANAGEMENT: 'PRIVILEGE_FILE_UPLOAD_MODELD_VEHICLE_USAGE_MANAGEMENT',
  FILE_UPLOAD_CCC_CERTIFICATION: 'PRIVILEGE_FILE_UPLOAD_CCC_CERTIFICATION',
  FILE_UPLOAD_DIGITAL_SALES: 'PRIVILEGE_FILE_UPLOAD_DIGITAL_SALES',
  FILE_UPLOAD_CESAR_WS: 'PRIVILEGE_FILE_UPLOAD_CESAR_WS_FILE_UPLOAD',
  FILE_UPLOAD_CESAR_CANCEL_WS: 'PRIVILEGE_FILE_UPLOAD_CESAR_CANCEL_WS_FILE_UPLOAD',
  FILE_UPLOAD_CESAR_RT: 'PRIVILEGE_FILE_UPLOAD_CESAR_RT_FILE_UPLOAD',
  FILE_UPLOAD_CESAR_CANCEL_RT: 'PRIVILEGE_FILE_UPLOAD_CESAR_CANCEL_RT_FILE_UPLOAD',
  FILE_UPLOAD_OPTION_PACKAGE: 'PRIVILEGE_FILE_UPLOAD_OPTION_PACKAGE_FILE_UPLOAD',
  FILE_UPLOAD_OPTION_PACKAGE_V2: 'PRIVILEGE_FILE_UPLOAD_OPTION_PACKAGE_FILE_UPLOAD_V2',
  FILE_UPLOAD_PT_CAR_PRICE: 'PRIVILEGE_FILE_UPLOAD_PT_CAR_PRICE',
  FILE_UPLOAD_PBP_FORWARD_TRANSPORT: 'PRIVILEGE_FILE_UPLOAD_PBP_FORWARD_TRANSPORT_APPLICATION',
  FILE_UPLOAD_CONTRACT_READY_MANAGEMENT: 'PRIVILEGE_FILE_UPLOAD_CONTRACT_READY_MANAGEMENT',
  FILE_UPLOAD_OEM_MANUAL_ALLOCATION_MANAGEMENT: 'PRIVILEGE_FILE_UPLOAD_OEM_MANUAL_ALLOCATION_MANAGEMENT',
  UAM: 'PRIVILEGE_UAM',
  LOG: 'PRIVILEGE_LOG',
  MODELD: 'PRIVILEGE_MODEL_D',
  POPT: 'PRIVILEGE_POPT',
  DIGITAL_SALES_EDIT: 'PRIVILEGE_FILE_DIGITAL_SALES_EDIT',
  VEHICLE_CHANGE_LOG: 'PRIVILEGE_VEHICLE_CHANGE_LOG',
  PT_CAR_PRICE_REPORT: 'PRIVILEGE_PT_CAR_PRICE_REPORT',
  BLOCK_MANAGEMENT_REPORT: 'PRIVILEGE_BLOCK_MANAGEMENT_REPORT',
  FILE_UPLOAD_MANUAL_REPLACEMENT: 'PRIVILEGE_FILE_UPLOAD_MANUAL_REPLACEMENT',
  OPTION_PACKAGE_MAPPING_REPORT: 'PRIVILEGE_OPTION_PACKAGE_MAPPING_REPORT',
  CESAR_MONITOR: 'CESAR_MONITOR',
  STOCK_DAY_DISPLAY_PERIOD_MANAGEMENT: 'PRIVILEGE_STOCK_DAY_DISPLAY_PERIOD_MANAGEMENT',
  BTO_SEARCH: 'BTO_SEARCH',
  CBU_WS_RATIO_MANAGEMENT: 'CBU_WS_RATIO_MANAGEMENT',
  PBP_WS_RATIO_MANAGEMENT: 'PBP_WS_RATIO_MANAGEMENT',
  STOCK_TAKING_HISTORY: 'STOCK_TAKING_HISTORY',
  UNHAPPY_CASE_PROPOSAL_MANAGEMENT: 'UNHAPPY_CASE_PROPOSAL_MANAGEMENT',
  PRIVILEGE_TAKE_RATE_REPORT: 'PRIVILEGE_TAKE_RATE_REPORT',
  PRIVILEGE_SOP_SEARCH_USAGE_REPORT: 'PRIVILEGE_SOP_SEARCH_USAGE_REPORT',
  PRIVILEGE_NOTIFICATION_PRE_CONTRACT_ALERT: 'PRIVILEGE_NOTIFICATION_PRE_CONTRACT_ALERT',
  PRIVILEGE_NOTIFICATION_ONLINE_REVERSED_STOCK: 'PRIVILEGE_NOTIFICATION_ONLINE_REVERSED_STOCK',
  PRIVILEGE_NOTIFICATION_POST_CONTRACT_ALERT: 'PRIVILEGE_NOTIFICATION_POST_CONTRACT_ALERT',
  PRIVILEGE_NOTIFICATION_FAPIAO_REISSUE_ALERT: 'PRIVILEGE_NOTIFICATION_FAPIAO_REISSUE_ALERT',
  PRIVILEGE_NOTIFICATION_CONFIRMED_FAPIAO_RECEIVED: 'PRIVILEGE_NOTIFICATION_CONFIRMED_FAPIAO_RECEIVED',
  PRIVILEGE_NOTIFICATION_DOWNLOAD_CENTER: 'PRIVILEGE_NOTIFICATION_DOWNLOAD_CENTER',
  PRIVILEGE_PRICE_FUNCTION: 'PRIVILEGE_PRICE_FUNCTION',
  FILE_UPLOAD_STOCK_TAKING_AT_AGENT: 'PRIVILEGE_FILE_UPLOAD_STOCK_TAKING_AT_AGENT',
  FILE_UPLOAD_MAINTAIN_FAPIAO_MAILING_INFO: 'PRIVILEGE_FILE_UPLOAD_MAINTAIN_FAPIAO_MAILING_INFO',
  FILE_UPLOAD_BLOCK_MANAGEMENT: 'PRIVILEGE_FILE_UPLOAD_BLOCK_MANAGEMENT',
  BLOCK_MANAGEMENT_SALES_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_SALES_BLOCK',
  BLOCK_MANAGEMENT_PRODUCT_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_PRODUCT_BLOCK',
  BLOCK_MANAGEMENT_PRICE_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_PRICE_BLOCK',
  BLOCK_MANAGEMENT_DAMAGE_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_DAMAGE_BLOCK',
  BLOCK_MANAGEMENT_LOGISTICS_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_LOGISTICS_BLOCK',
  BLOCK_MANAGEMENT_HANDOVER_BLOCK: 'PRIVILEGE_BLOCK_MANAGEMENT_HANDOVER_BLOCK',
};
