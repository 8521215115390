import {
  Input,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  Tag,
  Upload,
  Icon,
  Loading,
  MessageBox,
  Message,
  Notification,
  Tooltip,
  Dialog,
  Button,
  Divider,
  Popover,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  Col,
  Row,
  FormItem,
  DatePicker,
  TimePicker,
  Table,
  TableColumn,
  Pagination,
  Tabs,
  TabPane,
  Empty,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Radio,
  Badge,
  Switch,
  Card,
  Cascader,
  Image,
  RadioGroup,
} from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';
import Vue from 'vue';

locale.use(lang);

Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tag);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(Tooltip);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Popover);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(Col);
Vue.use(Row);
Vue.use(FormItem);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Empty);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Radio);
Vue.use(Badge);
Vue.use(Cascader);
Vue.use(Switch);
Vue.use(Card);
Vue.use(Image);
Vue.use(RadioGroup);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
