import get from 'lodash/get';
import NProgress from 'nprogress';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { HTTP_CODE, OperationScopeEnum, SOURCE } from '@/constant/config';
import 'nprogress/nprogress.css';
import { PRIVILEGE_CODE } from '@/constant/privilege';
import { User } from '@/interfaces/uam';
import { useOperationScopeStore } from '@/stores/operationScope';
import { useUamStore } from '@/stores/uam';

if (process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

const NO_SSO_DOMAIN = ['localhost'];

const ErrorPage = () => import('@/page/ErrorPage.vue');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => {
      if (NO_SSO_DOMAIN.includes(window.location.hostname)) {
        return import('@/page/LoginTemp.vue');
      }
      return import('@/page/Login.vue');
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/page/Home.vue'),
    children: [
      {
        path: '/home/search',
        name: 'search',
        component: () => import('@/page/Search.vue'),
        meta: { privileges: [PRIVILEGE_CODE.SEARCH] },
      },
      {
        path: '/home/search/result',
        name: 'search-result',
        component: () => import('@/page/search/SearchResult.vue'),
        meta: { privileges: [PRIVILEGE_CODE.SEARCH] },
      },
      {
        path: '/home/search/result/digital-sales-batch-edit',
        name: 'digital-sales-batch-edit',
        component: () => import('@/page/search/BatchEditDigitalSales.vue'),
      },
      {
        path: '/home/upload',
        name: 'upload',
        component: () => import('@/page/FileUpload.vue'),
        meta: {
          privileges: [
            PRIVILEGE_CODE.FILE_UPLOAD_ETA_VESSEL_NAME,
            PRIVILEGE_CODE.FILE_UPLOAD_TO_BE_CANCEL_LIST,
            PRIVILEGE_CODE.FILE_UPLOAD_PBP_ALLOCATION_BILLING,
            PRIVILEGE_CODE.FILE_UPLOAD_MODELD_VU_MANAGEMENT,
            PRIVILEGE_CODE.FILE_UPLOAD_CCC_CERTIFICATION,
            PRIVILEGE_CODE.FILE_UPLOAD_DIGITAL_SALES,
            PRIVILEGE_CODE.FILE_UPLOAD_CESAR_WS,
            PRIVILEGE_CODE.FILE_UPLOAD_CESAR_CANCEL_WS,
            PRIVILEGE_CODE.FILE_UPLOAD_CESAR_RT,
            PRIVILEGE_CODE.FILE_UPLOAD_CESAR_CANCEL_RT,
            PRIVILEGE_CODE.FILE_UPLOAD_OPTION_PACKAGE,
            PRIVILEGE_CODE.FILE_UPLOAD_OPTION_PACKAGE_V2,
            PRIVILEGE_CODE.FILE_UPLOAD_PT_CAR_PRICE,
            PRIVILEGE_CODE.FILE_UPLOAD_PBP_FORWARD_TRANSPORT,
            PRIVILEGE_CODE.FILE_UPLOAD_CONTRACT_READY_MANAGEMENT,
            PRIVILEGE_CODE.FILE_UPLOAD_OEM_MANUAL_ALLOCATION_MANAGEMENT,
            PRIVILEGE_CODE.FILE_UPLOAD_MANUAL_REPLACEMENT,
            PRIVILEGE_CODE.FILE_UPLOAD_STOCK_TAKING_AT_AGENT,
            PRIVILEGE_CODE.FILE_UPLOAD_MAINTAIN_FAPIAO_MAILING_INFO,
            PRIVILEGE_CODE.FILE_UPLOAD_BLOCK_MANAGEMENT,
          ],
        },
      },
      {
        path: '/home/template',
        name: 'template',
        component: () => import('@/page/SearchTemplate.vue'),
        meta: { privileges: [PRIVILEGE_CODE.SEARCH] },
      },
      {
        path: '/home/user-guide',
        name: 'user-guide',
        component: () => import('@/page/UserGuide.vue'),
      },
      {
        path: '/home/uam',
        name: 'uam',
        component: () => import('@/page/uam/Uam.vue'),
        meta: { privileges: [PRIVILEGE_CODE.UAM] },
      },
      {
        path: '/home/log',
        name: 'log',
        component: () => import('@/page/log/Log.vue'),
        meta: { privileges: [PRIVILEGE_CODE.LOG] },
      },
      {
        path: '/home/log/history/:itemId',
        name: 'log-history',
        props: true,
        component: () => import('@/page/log/History.vue'),
        meta: { privileges: [PRIVILEGE_CODE.LOG] },
      },
      {
        path: '/template-detail/:id?',
        name: 'template-detail',
        component: () => import('@/page/template/TemplateDetail.vue'),
        meta: { privileges: [PRIVILEGE_CODE.SEARCH] },
        props: true,
      },
      {
        path: '/vehicle/detail/:id',
        name: 'vehicle-detail',
        component: () => import('@/page/vehicle/VehicleDetails.vue'),
        meta: { privileges: [PRIVILEGE_CODE.SEARCH] },
        props: true,
      },
      {
        path: '/order/detail/:id',
        name: 'order-detail',
        component: () => import('@/page/order/OrderDetail.vue'),
        props: true,
      },
      {
        path: '/home/report',
        name: 'report',
        component: () => import('@/page/Report.vue'),
        meta: {
          privileges: [
            PRIVILEGE_CODE.ALLOCATION_APPLICATION,
            PRIVILEGE_CODE.POPT,
            PRIVILEGE_CODE.VEHICLE_CHANGE_LOG,
            PRIVILEGE_CODE.PT_CAR_PRICE_REPORT,
            PRIVILEGE_CODE.OPTION_PACKAGE_MAPPING_REPORT,
            PRIVILEGE_CODE.CBU_WS_RATIO_MANAGEMENT,
            PRIVILEGE_CODE.PBP_WS_RATIO_MANAGEMENT,
            PRIVILEGE_CODE.STOCK_TAKING_HISTORY,
            PRIVILEGE_CODE.BLOCK_MANAGEMENT_REPORT,
            PRIVILEGE_CODE.PRIVILEGE_PRICE_FUNCTION,
            PRIVILEGE_CODE.PRIVILEGE_TAKE_RATE_REPORT,
          ],
        },
      },
      {
        path: '/home/report/application-status',
        name: 'application-status',
        component: () => import('@/page/report/ApplicationStatus.vue'),
        meta: { privileges: [PRIVILEGE_CODE.ALLOCATION_APPLICATION] },
      },
      {
        path: '/home/report/popt',
        name: 'popt',
        component: () => import('@/page/report/Popt.vue'),
        meta: { privileges: [PRIVILEGE_CODE.POPT] },
      },
      {
        path: '/home/report/vehicle-change-log',
        name: 'vehicle-change-log',
        component: () => import('@/page/report/VehicleChangeLog.vue'),
        meta: { privileges: [PRIVILEGE_CODE.VEHICLE_CHANGE_LOG] },
      },
      {
        path: '/home/report/pt-car-price',
        name: 'pt-car-price',
        component: () => import('@/page/report/PTCarPrice.vue'),
        meta: { privileges: [PRIVILEGE_CODE.PT_CAR_PRICE_REPORT] },
      },
      {
        path: '/home/report/block-management',
        name: 'block-management',
        component: () => import('@/page/report/BlockManagement.vue'),
        meta: { privileges: [PRIVILEGE_CODE.BLOCK_MANAGEMENT_REPORT] },
      },
      {
        path: '/home/report/option-package-mapping',
        name: 'option-package-mapping',
        component: () => import('@/page/report/option-package/OptionPackageMapping.vue'),
        meta: { privileges: [PRIVILEGE_CODE.OPTION_PACKAGE_MAPPING_REPORT] },
      },
      {
        path: '/home/report/option-package-mapping/:nstReleaseDateAndYear/history',
        name: 'option-package-history',
        component: () => import('@/page/report/option-package/OptionPackageHistory.vue'),
        meta: { privileges: [PRIVILEGE_CODE.OPTION_PACKAGE_MAPPING_REPORT] },
        props: true,
      },
      {
        path: '/home/report/take-rate-report',
        name: 'take-rate-report',
        component: () => import('@/page/report/TakeRateReport.vue'),
        meta: { privileges: [PRIVILEGE_CODE.PRIVILEGE_TAKE_RATE_REPORT] },
      },
      {
        path: '/home/report/price-on-model-level',
        name: 'price-on-model-level',
        component: () => import('@/page/report/price/PriceOnModelLevel.vue'),
        meta: { privileges: [PRIVILEGE_CODE.PRIVILEGE_PRICE_FUNCTION] },
        props: true,
      },
      {
        path: '/home/report/sop-search-usage-report',
        name: 'sop-search-usage-report',
        component: () => import('@/page/report/SOPSearchUsageReport.vue'),
        meta: { privileges: [PRIVILEGE_CODE.PRIVILEGE_SOP_SEARCH_USAGE_REPORT] },
        props: true,
      },
      {
        path: '/home/report/price-on-model-level/history/:bm6/:fullNst/:modelYearAndChangeYear',
        name: 'price-on-model-level-history',
        component: () => import('@/page/report/price/PriceOnModelLevelHistory.vue'),
        meta: { privileges: [PRIVILEGE_CODE.PRIVILEGE_PRICE_FUNCTION] },
        props: true,
      },
      {
        path: '/home/notification',
        name: 'notification',
        component: () => import('@/page/notification/Notification.vue'),
        meta: {
          privileges: [
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_PRE_CONTRACT_ALERT,
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_ONLINE_REVERSED_STOCK,
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_POST_CONTRACT_ALERT,
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_FAPIAO_REISSUE_ALERT,
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_CONFIRMED_FAPIAO_RECEIVED,
            PRIVILEGE_CODE.PRIVILEGE_NOTIFICATION_DOWNLOAD_CENTER,
          ],
        },
      },
      {
        path: '/home/403',
        name: '403',
        component: ErrorPage,
      },
    ],
  },
  {
    path: '*',
    name: 'error-page',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const getUserInfo = async () => {
  const uamStore = useUamStore();
  const operationScopeState = useOperationScopeStore();
  try {
    await uamStore.getUserInfo();
    if (
      operationScopeState.operationScope === OperationScopeEnum.MODEL_D &&
      !uamStore.user?.privileges.some((item) => item.code === PRIVILEGE_CODE.MODELD)
    ) {
      operationScopeState.operationScope = OperationScopeEnum.ALL_VEHICLE;
    }
  } catch (e) {
    const code = get(e, 'response.status');
    if (code === HTTP_CODE.UNAUTHORIZED || code === HTTP_CODE.NOT_FOUND) {
      uamStore.user = undefined;
      localStorage.removeItem('token');
    }
  }
};

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  await getUserInfo();

  if (['login', 'error-page'].includes(to.name as string)) {
    if (to.query.source === SOURCE.AIA) {
      sessionStorage.setItem('source', SOURCE.AIA);
    }
    return next();
  }

  const user: User = useUamStore().user as User;
  const hasExistPagePrivilege: boolean =
    !to.meta?.privileges || user?.privileges?.some((privilege) => to.meta?.privileges.includes(privilege.code));
  if (!user?.privileges) {
    return next({ name: 'login' });
  }
  if (hasExistPagePrivilege) {
    return next();
  }
  if (!hasExistPagePrivilege) {
    return next({ name: 'home' });
  }
  return next({ name: '403' });
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
