import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import omit from 'lodash/omit';

import {
  DATA_SOURCE,
  DATE_TYPE_VALUE,
  DOMAIN_FOR_DISPLAY,
  FieldDataSourceOrder,
  GROUP_COLOR,
  SEARCH_SORT,
  SOURCE,
  TAG,
} from '@/constant/config';
import { DownloadField, SearchField, SearchRenderField } from '@/interfaces/search';
import router from '@/router';

import { formatDateTime, formatDay, formatMonth } from './dateTime';

export const getOrderByDataSource = (dataSource: string): number =>
  ({
    [DATA_SOURCE.GO]: 1,
    [DATA_SOURCE.CESAR]: 2,
    [DATA_SOURCE.VLMS]: 3,
    [DATA_SOURCE.SOP]: 4,
    [DATA_SOURCE.OTR]: 5,
    [DATA_SOURCE.Commerce]: 6,
    [DATA_SOURCE.VPS]: 7,
    [DATA_SOURCE.Others]: 8,
    [DATA_SOURCE.DMD]: 9,
    [DATA_SOURCE.BBAC]: 10,
    [DATA_SOURCE.DSP]: 11,
  }[dataSource]);

export const getOrderByDomain = (dataSource: string): number =>
  ({
    [DOMAIN_FOR_DISPLAY.ModelD]: 1,
    [DOMAIN_FOR_DISPLAY.Vehicle]: 2,
    [DOMAIN_FOR_DISPLAY.Order]: 3,
    [DOMAIN_FOR_DISPLAY.Production]: 4,
    [DOMAIN_FOR_DISPLAY.Logistics]: 5,
    [DOMAIN_FOR_DISPLAY.Dealer]: 6,
    [DOMAIN_FOR_DISPLAY.Agent]: 7,
    [DOMAIN_FOR_DISPLAY.Wholesale]: 8,
    [DOMAIN_FOR_DISPLAY.Retail]: 9,
    [DOMAIN_FOR_DISPLAY.WholesaleAndRetail]: 10,
    [DOMAIN_FOR_DISPLAY.Price]: 11,
    [DOMAIN_FOR_DISPLAY.DigitalSales]: 12,
    [DOMAIN_FOR_DISPLAY.SalesOrder]: 13,
    [DOMAIN_FOR_DISPLAY.Block]: 14,
  }[dataSource]);

export const getDomainByOrder = (dataSource: string): string =>
  ({
    1: DOMAIN_FOR_DISPLAY.ModelD,
    2: DOMAIN_FOR_DISPLAY.Vehicle,
    3: DOMAIN_FOR_DISPLAY.Order,
    4: DOMAIN_FOR_DISPLAY.Production,
    5: DOMAIN_FOR_DISPLAY.Logistics,
    6: DOMAIN_FOR_DISPLAY.Dealer,
    7: DOMAIN_FOR_DISPLAY.Agent,
    8: DOMAIN_FOR_DISPLAY.Wholesale,
    9: DOMAIN_FOR_DISPLAY.Retail,
    10: DOMAIN_FOR_DISPLAY.WholesaleAndRetail,
    11: DOMAIN_FOR_DISPLAY.Price,
    12: DOMAIN_FOR_DISPLAY.DigitalSales,
    13: DOMAIN_FOR_DISPLAY.SalesOrder,
    14: DOMAIN_FOR_DISPLAY.Block,
  }[dataSource] as string);

export const getDateSourceByOrder = (dataSource: string): string =>
  ({
    1: DATA_SOURCE.GO,
    2: DATA_SOURCE.CESAR,
    3: DATA_SOURCE.VLMS,
    4: DATA_SOURCE.SOP,
    5: DATA_SOURCE.OTR,
    6: DATA_SOURCE.Commerce,
    7: DATA_SOURCE.VPS,
    8: DATA_SOURCE.Others,
    9: DATA_SOURCE.DMD,
    10: DATA_SOURCE.BBAC,
    11: DATA_SOURCE.DSP,
  }[dataSource] as string);

export const groupDomain = <T extends SearchRenderField | DownloadField>(formatData: T[]) => {
  const domainGroup = groupBy(formatData, 'domainOrder');
  const domainData: Record<string, T[]> = {};
  Object.keys(domainGroup).forEach((item) => {
    domainData[getDomainByOrder(item)] = domainGroup[item];
  });
  return domainData;
};

export const groupDataSource = <T>(formatData: T[]) => {
  const dataSourceGroup = groupBy(formatData, 'dataSourceOrder');
  const dataSourceData: Record<string, T[]> = {};
  Object.keys(dataSourceGroup).forEach((item) => {
    dataSourceData[getDateSourceByOrder(item)] = dataSourceGroup[item];
  });
  return dataSourceData;
};

export const getColorByGroup = (group: string) => {
  const result = GROUP_COLOR[group];
  if (!result && group) {
    if (group.indexOf('Wholesale') !== -1) {
      return GROUP_COLOR['Wholesale'];
    } else if (group.indexOf('Model') !== -1) {
      return GROUP_COLOR['ModelD'];
    } else if (group.indexOf('Digital') !== -1 || group.indexOf('DIGITAL_SALES') !== -1) {
      return GROUP_COLOR['DigitalSales'];
    } else if (group.indexOf('Sales Order') !== -1 || group.indexOf('SALES_ORDER') !== -1) {
      return GROUP_COLOR['SalesOrder'];
    } else if (group === 'COMMERCE') {
      return GROUP_COLOR['Commerce'];
    }
  }
  return result;
};
const displayNameMap: Record<string, string> = {
  WholesaleAndRetail: DOMAIN_FOR_DISPLAY.WholesaleAndRetail,
  ModelD: DOMAIN_FOR_DISPLAY.ModelD,
  DIGITAL_SALES: DOMAIN_FOR_DISPLAY.DigitalSales,
  SALES_ORDER: DOMAIN_FOR_DISPLAY.SalesOrder,
};
export const getDisplayName = (key: string) => displayNameMap[key] ?? key;

export const toLoginPage = () => {
  localStorage.clear();
  router.push('/login');
};

export const sortSearch = (data: Record<string, SearchRenderField[]>) => {
  Object.keys(data).forEach((key) => {
    if (SEARCH_SORT[key]) {
      data[key].forEach((item) => {
        item.sort = getSearchSort(SEARCH_SORT[key], item.name);
      });
      data[key] = data[key].sort((a, b) => a.order - b.order);
      data[key] = data[key].sort((a, b) => b.sort - a.sort);
    }
  });
  return data;
};

const getSearchSort = (sortList: string[], key: string) => {
  for (let index = 0; index < sortList.length; index++) {
    if (key.includes(sortList[index])) {
      return index * -1;
    }
  }
  return -999;
};

export const omitData = (data: SearchRenderField[]): SearchField[] =>
  map(
    data,
    (item) =>
      <SearchField>(
        omit(item, [
          'dataSourceColor',
          'dataSourceOrder',
          'domainColor',
          'domainOrder',
          'dataSource',
          'operation',
          'optionValues',
          'optionCode',
          'sort',
          'savedValue',
        ])
      )
  );

export const filterEmptyData = (data: SearchRenderField[]): SearchField[] => {
  const nonEmptyData = filter(
    data,
    (item) =>
      (item.includeValues && item.includeValues.length > 0) ||
      (item.excludeValues && item.excludeValues.length > 0) ||
      [DATE_TYPE_VALUE.NOT_BLANK, DATE_TYPE_VALUE.BLANK].includes(item.type)
  );
  return omitData(map(nonEmptyData, (item) => format(item)));
};

const format = (item: SearchRenderField): SearchRenderField => {
  if (item.tag === TAG.DATE || item.tag === TAG.SINGLE_DATE) {
    return {
      ...item,
      includeValues: map(item.includeValues, (value) => (value ? formatDay(value) : null)),
      excludeValues: map(item.excludeValues, (value) => (value ? formatDay(value) : null)),
    };
  } else if (item.tag === TAG.DATE_TIME) {
    return {
      ...item,
      includeValues: map(item.includeValues, (value) => (value ? formatDateTime(value) : null)),
      excludeValues: map(item.excludeValues, (value) => (value ? formatDateTime(value) : null)),
    };
  } else if (item.tag === TAG.MONTH) {
    return {
      ...item,
      includeValues: map(item.includeValues, (value) => (value ? formatMonth(value) : null)),
      excludeValues: map(item.excludeValues, (value) => (value ? formatMonth(value) : null)),
    };
  }
  return item;
};

export const NON_NUMERIC_AND_NON_LETTER = [
  'VIN No.',
  'FIN No.',
  'Configuration',
  'Paint Lower',
  'Paint Upper',
  'Upholstery',
];
export const NON_NUMERIC = ['Commission No.'];

export const inputValidate = (fieldName: string) => {
  if (NON_NUMERIC_AND_NON_LETTER.includes(fieldName)) {
    return /^[a-zA-Z0-9\s]*$/;
  }
  if (NON_NUMERIC.includes(fieldName)) {
    return /^[0-9]{10}$/;
  }
  return /.*/;
};

export const needValidateField = (fieldName: string) =>
  NON_NUMERIC_AND_NON_LETTER.includes(fieldName) || NON_NUMERIC.includes(fieldName);

export const isAIA = () => sessionStorage.getItem('source') === SOURCE.AIA;

export const getDefaultFields = () => (isAIA() ? ['Commission No.'] : []);

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const LESS_100_AND_4_DECIMAL_REG = /^([0-9](\.\d{1,4}))$|^([1-9][0-9](\.\d{1,4})?)$|^100$|^[1-9]$/;

export const DATA_SOURCE_SPLIT = [] as string[];

export const getDatasource = (dataSourceData: string[]) => {
  let dataSource = [] as string[];
  dataSourceData.forEach((item) => {
    if (DATA_SOURCE_SPLIT.includes(item)) {
      dataSource.push(...item.split('_'));
      dataSource.push(item);
    } else {
      dataSource.push(item);
    }
  });
  dataSource = [...new Set(dataSource)];
  return dataSource.sort((a, b) => FieldDataSourceOrder.indexOf(a) - FieldDataSourceOrder.indexOf(b));
};

export const validateCommissionNumber = (commisionNumber: string) => {
  return /^\d{10}$/.test(commisionNumber);
};
